<template>
  <div class="page">
    <div class="title">
      <el-image style="width: 22px; height: 22px;" :src="require('./../../assets/new_icon/xueyuan.png')"></el-image>
      <span style="margin-left: 10px;" @click="getStatusList(null)" :class="{ statusAct: this.status == null }">我的学员
        <span style="color: #459EFF;">{{ num0 }}</span> 位</span>
      <span style="margin-left: 40px;" @click="getStatusList(2)" :class="{ statusAct: this.status == 2 }">待审核<span
          style="color: #DC3C00;"> {{ num1 }} </span>位</span>
      <span style="margin-left: 20px;" @click="getStatusList(3)" :class="{ statusAct: this.status == 3 }">已审核<span
          style="color: #459EFF;"> {{ num2 }} </span>位</span>


      <span class="add" @click="dialogVisible = true"><el-image class="img"
          :src="require('./../../assets/new_icon/add.png')"></el-image> 添加学员</span>
    </div>

    <div class="subject">
      <el-select v-model="subject" class="rounded-select" placeholder="请选择" size="mini" style="width: 120px"
        @change="changeTypeSubject">
        <el-option v-for="item in subjectType" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <span :class="{ 'subject-name': true, 'subject-name-act': item.id == subject_id }"
        v-for="(item, i ) in subjectArray" :key="i" @click="selectSubjectId(item.id)">{{
          item.name }}</span>

      <span class="subject-order" @click="orderList()">按照分数排序
        <el-image class="subject-order-img" :src="require('./../../assets/new_icon/order.png')"></el-image>

      </span>
      <el-input placeholder="请输入内容" v-model="user_name" style="width: 220px;float: right; " size="mini" class="search">
        <el-button slot="append" class="btn" size="mini" @click="getList()">搜索</el-button>
      </el-input>
    </div>


    <!-- 用户列表 -->
    <div>
      <user_list :UserListData="UserListData" @refresh="getList()"> </user_list>
    </div>

    <!-- 分页 -->
    <div class="pagination">
      <el-pagination :page-size="tables.pageSize" :total="tables.total" class="page-box" layout="prev, pager, next"
        @current-change="pageChange" />
    </div>

    <!-- 添加学生弹窗 -->
    <el-drawer :visible.sync="dialogVisible" class="cre" title='添加学员信息' :with-header="false" size="1220px"
      append-to-body destroy-on-close>
      <create_user @closeEdit='close' :userData="null"></create_user>
    </el-drawer>
  </div>
</template>

<script>
import user_list from "./components/UserList";
import create_user from "./components/editUser";
//time: 2021/1/22
export default {
  name: "User",
  components: {
    user_list,
    create_user,
  },
  data() {
    return {
      url: './../../assets/new_icon/xueyuan.png',
      subject: null,
      subject_id: null,
      UserListData: [],
      ExpertInfo: "",
      order: "id",
      dialogVisible: false,
      tables: {
        currentPage: 1,
        pageSize: 12,
        total: 1,
      },
      status: null,

      user_name: null,

      subjectType: [{
        value: 1,
        label: '物理组'
      }, {
        value: 4,
        label: '历史组'
      },
      {
        value: null,
        label: '全部'
      }],
      subjectArray: [],
      subjectArray1: [
        {
          id: 1,
          name: '物化政'
        },
        {
          id: 2,
          name: '物化地'
        },
        {
          id: 3,
          name: '物生政'
        },
        {
          id: 4,
          name: '物化生'
        },
        {
          id: 5,
          name: '物地政'
        },
        {
          id: 6,
          name: '物生地'
        }
      ],
      subjectArray2: [
        {
          id: 7,
          name: '史化政'
        },
        {
          id: 8,
          name: '史政生'
        },
        {
          id: 9,
          name: '史政地'
        },
        {
          id: 10,
          name: '史化地'
        },
        {
          id: 11,
          name: '史化生'
        },
        {
          id: 12,
          name: '史生地'
        }
      ],
      num0: 0,
      num1: 0,
      num2: 0,
    };
  },
  created() {
    this.subjectArray = this.subjectArray1

    // 获取导师信息
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));

    //判断登陆状态
    this.checkLoginState()


  },
  methods: {
    getStatusList(e) {
      console.log(e)
      if (this.status == e) {
        this.status = null
      } else {
        this.status = e
      }
      this.getList()
    },
    changeTypeSubject() {
      if (this.subject == 1) {
        this.subjectArray = this.subjectArray1
      } else {
        this.subjectArray = this.subjectArray2
      }
      this.subject_id = null
      this.getList()
    },

    selectSubjectId(id) {
      if (this.subject_id == id) {
        this.subject_id = null
      } else {
        this.subject_id = id
      }
      this.getList()
    },

    checkLoginState() {
      let TOKEN = localStorage.getItem("TOKEN")

      this.$fecth.post("login/checkLoginState", { token: TOKEN }).then((res) => {
        if (res.code == 200) {
          this.getList()
        }
      });
    },

    orderList() {
      this.order = this.order == 'desc' ? 'asc' : 'desc'
      this.getList()
    },

    // 获取学生列表
    getList() {
      let data = {
        subject: this.subject,
        subject_id: this.subject_id,
        user_name: this.user_name,
        status: this.status,

        parent_id: this.ExpertInfo.id,
        order: this.order,
        pageSize: this.tables.pageSize,
        pageNum: this.tables.currentPage,
      };

      this.$fecth.post("user/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.UserListData = data.lists;
          this.tables.total = data.total;
          this.num0 = data.num0;
          this.num1 = data.num1;
          this.num2 = data.num2;
          this.tables.currentPage = data.pageNum;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    close() {
      this.dialogVisible = false;
      this.getList();
    },
    pageChange(val) {
      this.tables.currentPage = val;
      this.getList();
    },
  },
};
</script>
<style lang="less">
.rounded-select {
  .el-input__inner {
    border-radius: 30px;
  }
}

.search {
  .el-input__inner {
    border-radius: 30px 0 0 30px;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    color: white;
    background: #2B7BF3;
    border-radius: 0 30px 30px 0;
    border: #2B7BF3;
  }
}
</style>

<style lang="less" scoped>
.page {
  background: white;
  font-size: 18px;
  padding: 10px 60px;

  .title {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    // justify-content: center;
    position: relative;

    /* 水平居中，如果需要的话 */
    .add {
      position: absolute;
      right: 0;

      .img {
        width: 22px;
        height: 22px;
        position: relative;
        top: 6px;
        right: 5px;
      }
    }


    .statusAct {
      border-bottom: 2px solid #2C89FF;
    }

    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #DFDFDF;
  }

  .subject {
    margin-top: 20px;
    margin-bottom: 20px;

    .subject-name {
      border: 1px solid #DFDFDF;
      display: inline-block;
      line-height: 26px;
      padding: 0 18px;
      position: relative;
      top: 1px;
      border-radius: 30px;
      margin-left: 20px;
    }

    .subject-order {
      display: inline-block;
      line-height: 26px;
      padding: 0 18px;
      position: relative;
      top: 1px;
      border-radius: 30px;
      margin-left: 20px;

      .subject-order-img {
        position: absolute;
        top: 6px;
        right: -4px;
      }
    }

    .subject-name-act {
      background: #2B7BF3;
      color: white;
    }
  }


}



.pagination {
  background: white;
  padding: 0;
}

.el-dialog__body {
  min-height: 200px;
}

.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 4px 0 0;
}

.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 4px 0 0 0;
}
</style>



<style lang="less" scoped>
.choose {
  background: white;
  margin-bottom: 12px;
  padding: 3px;
  display: flex;
  line-height: 25px;

  .fx {
    border-bottom: 1px solid rgb(11, 108, 218);
  }

  div {
    width: 80px;
    margin: 5px 0 5px 75px;
  }
}
</style>