<template>
  <div class="editUser">
    <el-form :model="form" label-width="120px" style="padding:0px 23px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="姓名">
            <el-input v-model="form.user_name" size="mini" placeholder="请填写姓名" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="性别">
            <el-radio-group v-model="form.is_sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="民族">
            <el-radio-group v-model="form.minzu">
              <el-radio :label="1">汉</el-radio>
              <el-radio :label="0">少数民族</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row>

        <el-col :span="6">
          <el-form-item label="科类">
            <el-radio-group v-model="form.subject">
              <el-radio :label="4">历史</el-radio>
              <el-radio :label="1">物理</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="16" :offset="0">
          <el-form-item label="再选科目(4选2)">
            <div style="color: #606266;"></div>
            <el-checkbox-group v-model="other_subject" :max="2">
              <el-checkbox :label="'2'">化学</el-checkbox>
              <el-checkbox :label="'3'">生物</el-checkbox>
              <el-checkbox :label="'6'">政治</el-checkbox>
              <el-checkbox :label="'5'">地理</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="高考成绩">
            <el-input v-model="form.score" size="mini" placeholder="输入分数" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="语文">
            <el-input v-model="form.chinese" size="mini" placeholder="输入分数" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数学">
            <el-input v-model="form.math" size="mini" placeholder="输入分数" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="英语">
            <el-input v-model="form.english" size="mini" placeholder="输入分数" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="手机号">
            <el-input v-model="form.mobile" size="mini" placeholder="输入手机号" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="所在省市">
            <el-input v-model="form.province" size="mini" placeholder="河南省" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="市">
            <el-select v-model="form.city" size="mini" placeholder="请选择" @change="selectCity($event)">
              <el-option v-for="item in cityList" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="县">
            <el-select v-model="form.district" size="mini" placeholder="请选择" @change="selectSchool($event)">
              <el-option v-for="item in districtList" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6">
          <el-form-item label="就读中学">
            <el-select v-model="form.school"  placeholder="请选择" size="mini">
              <el-option v-for="item in schoolList" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="就读年级">
            <el-input v-model="form.grade" size="mini" placeholder="年级" />
          </el-form-item>
        </el-col>
      </el-row>

      <div class="title" style="margin-bottom: 15px">意向省份</div>
      <el-form-item label="选择省份">
        <el-select v-model="form.like_city" size="mini" multiple placeholder="请选择" style="width: 480px">
          <el-option v-for="item in city" :key="item.city" :label="item.city" :value="item.city">
          </el-option>
        </el-select>
      </el-form-item>

      <div class="title" style="margin-bottom: 15px">排除省份</div>

      <el-form-item label="选择省份">
        <el-select v-model="form.no_like_city" size="mini" multiple placeholder="请选择" style="width: 480px">
          <el-option v-for="item in city" :key="item.city" :label="item.city" :value="item.city">
          </el-option>
        </el-select>
      </el-form-item>

      <div class="title" style="margin-bottom: 15px">特殊升学要求</div>
      <el-form-item label="选择">
        <el-select v-model="form.special_request" size="mini" multiple placeholder="请选择" style="width: 480px">
          <el-option v-for="item in spSpecialty" :key="item.name" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.tssxbz" size="mini" placeholder="备注" type="textarea"
          style="width: 300px; margin-bottom: 10px" />
      </el-form-item>

      <div class="title" style="margin-bottom: 15px">喜欢专业</div>
      <el-form-item label="喜欢专业">
        <el-input type="textarea" size="mini" style="width: 300px; margin-bottom: 10px" placeholder="请输入内容"
          v-model="form.like_sp" />
      </el-form-item>
      <div class="title" style="margin-bottom: 15px">排除专业</div>
      <el-form-item label="排除专业">
        <el-input type="textarea" size="mini" style="width: 300px; margin-bottom: 10px" placeholder="请输入内容"
          v-model="form.no_like_sp" />
      </el-form-item>

      <el-form-item>
        <div style="
              background: blue;
              color: white;
              border-radius: 10px;
              padding: 2px 20px;
              font-size: 15px;
              display: block;
              border-radius: 60px;
              color: #fff;
              background-color: #409eff;
              border-color: #409eff;
              width: 101px;
              line-height: 27px;
            " @click="onSubmit">
          确认修改
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import height_school from "@/assets/data/height_school.js";
import city_data_array from "@/assets/data/city_data.js";
import subjectList from "@/assets/data/subject.js";
export default {
  name: "",
  data() {
    return {
      form: {
        user_name: null,
        school: null,
        subject: null,
        is_sex: null,
        minzu: null,
        score: null
      },
      cityList: [],
      schoolList: [],
      districtList: [],
      spSpecialty: [
        { id: 0, name: "无" },
        { id: 1, name: "艺术类" },
        { id: 2, name: "体育类" },
        { id: 3, name: "军队院校" },
        { id: 4, name: "定向培养士官" },
        { id: 5, name: "公安院校" },
        { id: 6, name: "司法类" },
        { id: 7, name: "香港高校" },
        { id: 8, name: "澳门高校" },
        { id: 9, name: "定向就业" },
        { id: 10, name: "少数民族预科班" },
      ],
      other_subject: [],
      subjectList: subjectList
    };
  },
  props: ["userData"],
  computed: {},
  watch: {
    userData(item) {
      if (item) {
        this.form = item;
        this.other_subject = JSON.parse(this.form.other_subject)
      }
    },
  },
  created() {
    this.cityList = height_school;
    this.city = city_data_array;

    if (this.userData) {
      this.form = this.userData;
      this.other_subject = JSON.parse(this.form.other_subject)
    }
  },
  methods: {

    //选择高中
    selectCity(value) {
      let obj = {};
      obj = this.cityList.find((item) => {
        return item.label === value; // 筛选出匹配数据
      });
      this.form.district = null;
      this.districtList = obj.children;
    },
    selectSchool(value) {
      let obj = {};
      obj = this.districtList.find((item) => {
        return item.label === value; // 筛选出匹配数据
      });
      this.form.school = null;
      this.schoolList = obj.children;
      this.$forceUpdate()
    },
    onSubmit() {

      var subject_array = [];
      this.other_subject.forEach(item => {
        subject_array.push(item)
      })
      //获取选科-把三个科目放到一起
      subject_array.push(this.form.subject)

      subject_array = subject_array.map(function (item) {
        return Number(item);
      });
      //给科目排序
      subject_array.sort((a, b) => a - b)
      subject_array = subject_array.join('')
      this.form.subject_id = subjectList.indexOf(Number(subject_array))


      this.form.other_subject = JSON.stringify(this.other_subject)

      if (this.form.id) {
        this.$fecth.post("user/updateUserInfo", this.form).then((res) => {
          if (res) {
            this.$emit("closeEdit", 1);
            this.$notify({
              showClose: true,
              message: "更新成功！",
              type: "success",
            });
          }
        });
      } else {
        let ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
        this.form.parent_id = ExpertInfo.id;
        this.$fecth.post("user/add", this.form).then((res) => {
          if (res.code == 200) {
            this.$emit("closeEdit", 1);
            this.$notify({
              showClose: true,
              message: "更新成功！",
              type: "success",
            });
          }
          if (res.code == 500) {
            this.$notify({
              message: res.msg,
              type: "error",
            });
          }
        });
      }

    },
  },
};
</script>

<style scoped lang='less'>
.editUser {
  padding: 50px 20px;

  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>
